<template>
  <div class="landing">
    <a href="http://down.pinjamansuper.xyz">
      <img src="@/assets/img/google-play-btn.jpg" />
    </a>
    <a href="http://h5api.pinjamansuper.xyz">
      <img src="@/assets/img/web-btn.jpg" />
    </a>
    <a @click="go">
      <img src="@/assets/img/app-store-btn.jpg" />
    </a>
    <img class="bottom" src="@/assets/img/bottom.jpg" />
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'Landing',
  methods: {
    go () {
      request.post('downloadClick').then(() => {
        location.href = 'https://tinyurl.com/lio2sle'
      })
    }
  }
}
</script>

<style scoped lang="less">
.landing {
  width: 100%;
  min-height: 100vh;
  padding-top: 27.6vh;
  padding-bottom: 20px;
  box-sizing: border-box;
  background: url(../assets/img/bg.jpg) no-repeat;
  background-size: 100% 100%;

  a {
    display: block;
    width: 390px;
    margin-left: auto;
    margin-right: auto;

    &:nth-child(n+2) {
      margin-top: 15px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bottom {
    width: 82%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
